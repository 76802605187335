import React, { useState } from 'react';
import { Typography, Table, TableHead, TableBody, TableCell, TableRow, TableSortLabel, Grid, Box, Container, TableContainer, useMediaQuery, Theme, Paper, IconButton, TextField, Button, CircularProgress } from '@material-ui/core';
import { TrashCan } from 'mdi-material-ui';
import { useHistory } from 'react-router-dom';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';
import { RoutesEnum } from '../../../../constants';
import envConfig from '../../../../config/environment-config';
import { patientDataService, schedulesService } from '../../../../services';
import { localStorageMethods } from '../../common/helpers';
import { IPatientCrendentials } from '../../common/contracts';
import { LocalStorageKeysEnum } from '../../common/contracts';
import { inputLabelProps } from '../../common/styles';
import { ISchedule } from '../contracts';
import { Visibility } from '@material-ui/icons';

export function ScheduleList(): JSX.Element {
  const history = useHistory();
  const { showAlert } = useAlert();
  const { showDialogue, hideDialogue } = useDialogue();
  const [callApi, setCallApi] = useState<boolean>(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [sortConfig, setSortConfig] = useState({ key: '', direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  const addSufix = (route: string, sufix: string): string => {
    return route.endsWith(sufix) ? route : `${route}${sufix}`;
  };
  const sufix = '/unimed';

  const { data: patientData, isLoading: isLoadingPatientData } = patientDataService.getPatientData(
    Number(envConfig.boehringerProgramCode),
    patientCredentials?.healthPassportId || '',
    setCallApi,
  );

  const { data: schedulesByPatient, isLoading: isLoadingSchedulesByPatient } = schedulesService.listSchedules(
    envConfig.boehringerProgramFollowup,
    patientData?.data.cpf || '',
  );

  const handleAddSchedule = () => {
    history.push(patientCredentials?.isUnimed ? addSufix(RoutesEnum.PATIENT_SCHEDULES_ADD_ROUTE, sufix) : RoutesEnum.PATIENT_SCHEDULES_ADD_ROUTE);
  };

  const handleView = (idSchedule: string) => {
    history.push(patientCredentials?.isUnimed ? addSufix(`/panel/schedules/edit/${idSchedule}`, sufix) : `/panel/schedules/edit/${idSchedule}`);
  };

  const schedules = schedulesByPatient?.data.schedules || [];

  // const handleDelete = async (idSchedule: string) => {
  //   const onConfirm = () => {
  //     try {
  //       const success = true;
        
  //       if (success) {
  //         showAlert('Exclusão de agendamento', 'agendamento excluído com sucesso', 'success');
  //       } else {
  //         throw new Error('Erro ao excluir agendamento');
  //       }
  //     } catch (error) {
  //       showAlert('Exclusão de agendamento', 'Erro ao excluir agendamento', 'error');
  //     } finally {
  //       hideDialogue();
  //     }
  //   };

  //   showDialogue('Exclusão de agendamento', 'Deseja realmente excluir este agendamento?', 'warning', 'Sim', 'Não', onConfirm, hideDialogue);
  // };

  const columns = [
    { id: 'availability.startDate', label: 'Produto', useSort: false },
    { id: 'professional.professionalTypeDescription', label: 'Tipo do agendamento', useSort: false },
    { id: 'professional.name', label: 'Profissional', useSort: false },
    { id: 'availability.availableDate', label: 'Dia', useSort: false },
    { id: 'availabilityTime.availableTime', label: 'Hora', useSort: false },
  ];

  // Sort function
  const sortedSchedules = React.useMemo(() => {
    const sortableItems: any = [...schedules];
    // if (sortConfig !== null) {
    //   sortableItems.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => {
    //     if (a[sortConfig.key] < b[sortConfig.key]) {
    //       return sortConfig.direction === 'ascending' ? -1 : 1;
    //     }
    //     if (a[sortConfig.key] > b[sortConfig.key]) {
    //       return sortConfig.direction === 'ascending' ? 1 : -1;
    //     }
    //     return 0;
    //   });
    // }
    return sortableItems;
  }, [schedules, sortConfig]);

  const requestSort = (key: string) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getProductName = (product: any): string => {
    if(!product?.popularName && product?.ean) {
      return product?.ean;
    }

    if(product?.popularName && product?.ean) {
      return `${product?.popularName}`;
    }

    return '-'
  }

  // eslint-disable-next-line
  const filteredSchedules = sortedSchedules.filter((schedule: ISchedule) => {
    const { professional, product, availability } = schedule;
    return (
        (professional?.professionalTypeDescription?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (professional?.name?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (product?.ean?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (product?.popularName?.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
        (availability?.availableDate?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
    );  
  });

  if (isLoadingSchedulesByPatient || isLoadingPatientData) {
    return (
      <div style={{ margin: '200px auto', marginLeft: '48%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <Box my={2}>
        <Grid container spacing={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'left', width: '100%' }}>
            Meus Agendamentos
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '16px 0 36px 0', width: '100%' }} />

          <Grid container spacing={2} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} lg={8}>
              <TextField
                label="Buscar Agendamento"
                variant="outlined"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputLabelProps={inputLabelProps}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleAddSchedule}
                size="large"
                fullWidth
                style={!isMobile ? { height: '100%', marginTop: '-2px' } : {}}
              >
                Novo Agendamento
              </Button>
            </Grid>
          </Grid>

          <TableContainer component={Box} style={{ border: '1px solid rgba(224, 224, 224, 1)', borderRadius: '8px', width: '100%', overflow: 'auto' }}>
            <Table style={{ minWidth: isMobile ? 200 : 650 }} aria-label="Meus agendamentos">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.useSort && (
                        <TableSortLabel
                          active={sortConfig?.key === column.id}
                          direction={sortConfig?.direction === 'descending' ? 'desc' : 'asc'}
                          onClick={() => requestSort(column.id)}
                        >
                          <b>{column.label}</b>
                        </TableSortLabel>
                      )}
                      {!column.useSort && <b>{column.label}</b>}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSchedules?.map((schedule: ISchedule, index: number) => (
                  <TableRow key={schedule.idSchedule}>
                    <TableCell>
                      <IconButton
                        onClick={() => handleView(schedule.idSchedule.toString())}
                      >
                        <Visibility />
                      </IconButton>
                      {/* <IconButton
                        onClick={() => handleDelete(schedule.idSchedule.toString())}
                      >
                        <TrashCan />
                      </IconButton> */}
                    </TableCell>
                    <TableCell style={{ borderBottom: index === schedules.length - 1 ? 'none' : '' }}>{getProductName(schedule.product)}</TableCell>
                    <TableCell style={{ borderBottom: index === schedules.length - 1 ? 'none' : '' }}>{schedule.professional?.professionalTypeDescription ?? '-'}</TableCell>
                    <TableCell style={{ borderBottom: index === schedules.length - 1 ? 'none' : '' }}>{schedule.professional?.name ?? '-'}</TableCell>
                    <TableCell style={{ borderBottom: index === schedules.length - 1 ? 'none' : '' }}>{schedule.availability?.availableDate ?? '-'}</TableCell>
                    <TableCell style={{ borderBottom: index === schedules.length - 1 ? 'none' : '' }}>{schedule.availabilityTime?.availableTime.slice(0, 5) ?? '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Container>
  );
}

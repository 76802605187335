export enum RuleNameEnum {
    SINGLE = 'Single',
    KIT = 'Kit',
    SINGLE_NPS = 'Single NPS',
}

export function handleQuantityMessage(
    ruleName: RuleNameEnum, 
    boxesFrom: number | null, 
    boxesTo: number | null,
) {
    const boxes = boxesFrom === boxesTo ? (boxesTo ?? '') : `${boxesFrom} - ${boxesTo}`;
    const boxesText = boxes === 1 ? 'caixa' : 'caixas';

    const object = {
        [RuleNameEnum.SINGLE]: '1 Caixa',
        [RuleNameEnum.KIT]: `Kit ${boxes} ${boxesText}`,
        [RuleNameEnum.SINGLE_NPS]: `${boxes} ${boxesText}`,
    };

    return object[ruleName];
}
